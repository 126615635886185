<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="18px"
        width="22px"
        :alt="currentLocale.locale"
      />
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="18px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { getTranslationApi } from '@/api/action-defines/common';
import { localize } from 'vee-validate';

export default {
  components: {},
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      if (this.$i18n.locale == 'en') {
        localize('en');
      } else {
        localize('vi');
      }
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
    locales() {
      return [
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: this.$t('locale_language_en'),
        },
        {
          locale: 'vi',
          img: require('@/assets/images/flags/vi.png'),
          name: this.$t('locale_language_vi'),
        },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    changeLanguage(langCode) {
      getTranslationApi({ QueryParameters: { languageCode: langCode } }).then(
        (res) => {
          this.$i18n.setLocaleMessage(langCode, res.Data);
          this.$i18n.locale = langCode;
        }
      );
    },
  },
};
</script>

<style></style>
