var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}})],1),_c('div',{staticClass:"p-1 d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"d-none d-xl-block",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":_vm.toggleCollapsed}},[_c('ez-icon',{staticClass:"collapse-toggle-icon",attrs:{"icon":!_vm.isVerticalMenuCollapsed ||
          (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered)
            ? 'ez_keyboard_double_arrow_left'
            : 'ez_keyboard_double_arrow_right',"size":"20"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }