<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.FullName || userData.UserName }}
        </p>
      </div>
      <b-avatar size="40" :src="userData.Avatar" variant="light-primary">
        <ez-icon v-if="!userData.FullName" icon="ez_user" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex" 
      @click="userInfo_Click">
      <b-media>
        <template #aside>
          <b-avatar size="40px" variant="light-primary" :src="userData.Avatar">
            <ez-icon v-if="!userData.FullName" icon="ez_user" size="22" />
          </b-avatar>
        </template>
        <h5 class="text-primary">{{ userData.FullName || userData.UserName }}</h5>
        <b-card-text class="mb-0 text-secondary">
          {{ userData.Email }}</b-card-text
        >
      </b-media>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <ez-icon size="16" icon="ez_logout" class="mr-50 text-danger" />
      <span class="text-danger">{{ $t('user_signout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import { avatarText } from '@core/utils/filter';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    };
  },
  methods: {
    logout() {
      // // Remove userData from localStorage
      // // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // // Reset ability
      // this.$ability.update(initialAbility)

      // // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      this.$store.dispatch('auth/signOut');
    },
    userInfo_Click() {
      const urlUserInfo = `${process.env.VUE_APP_OIDC_PROVIDER_DOMAIN}/Manage`;
      window.open(urlUserInfo,'_blank');
    },
  },
  computed: {
    userData() {
      return this.$store.getters['auth/userProfile'];
    },
  },
};
</script>

<style>
.dropdown-user .dropdown-menu {
  width: 300px !important;
}
</style>
